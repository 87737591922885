import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import Page from 'components/page/Page';
import { ConfirmationModal, LoadingSpinner, useFetch } from 'common.ui';
import { profileClient } from 'api/MinSideClients';
import { UserAccessResponse, UserAccessType, OrganizationResponse } from 'api/minside';

import { ReactComponent as IconAltArrowRight } from 'assets/icons/alt-arrow-right-icon.svg';
import { Link } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { groupBy } from 'helpers/arrayHelpers';
import { GlobalApplicationName } from 'helpers/application/consts';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar-icon.svg';

import './Permissions.scss';

type UserAccessResponseOrganization = {
  Organization?: OrganizationResponse;
  UserAccess: UserAccessResponse;
};
function Permissions() {
  const { t } = useTranslation('pages');
  const [seq, setSeq] = useState<number>(0);
  const [deleteAccess, setDeleteAccess] = useState<UserAccessResponseOrganization>();
  const [userAccesses] = useFetch(async () => profileClient.apiProfileCurrentPermissionsGet(), undefined, false, [seq]);

  if (!userAccesses) {
    return <LoadingSpinner />;
  }

  async function onDeleteAccessConfirmed() {
    if (deleteAccess && deleteAccess.UserAccess.id) {
      if (deleteAccess.UserAccess.type === UserAccessType.Role) {
        if (deleteAccess.Organization?.organizationId) {
          await profileClient.apiProfileRolesRoleIdOrganizationOrganizationIdDelete({
            roleId: +deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await profileClient.apiProfileRolesRoleIdDelete({ roleId: +deleteAccess.UserAccess.id });
        }
      }
      if (deleteAccess.UserAccess.type === UserAccessType.Permission) {
        if (deleteAccess.Organization?.organizationId) {
          await profileClient.apiProfilePermissionsPermissionIdOrganizationOrganizationIdDelete({
            permissionId: +deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await profileClient.apiProfilePermissionsPermissionIdDelete({ permissionId: +deleteAccess.UserAccess.id });
        }
      }
      setSeq(seq + 1);
      setDeleteAccess(undefined);
    }
  }

  const accesses = userAccesses
    .filter((x) => !x.parentOrigin)
    .sort(
      (ua1, ua2) =>
        // we want the global application (Ikke henført til applikasjon) in the bottom
        (ua1.applicationId ? 0 : 1) - (ua2.applicationId ? 0 : 1) ||
        // and then order by the application name
        (ua1.applicationName || '').localeCompare(ua2.applicationName || '')
    );
  const apps = groupBy(accesses, (a) => a.applicationId || '');

  return (
    <Page header={t('access.permissions.title')}>
      <ConfirmationModal
        header={t('access.permissions.removeAccessModal.title')}
        show={deleteAccess !== undefined}
        onCancel={() => setDeleteAccess(undefined)}
        onAccept={onDeleteAccessConfirmed}
        yesText={t('access.permissions.removeAccessModal.yesText')}
        noText={t('access.permissions.removeAccessModal.noText')}
      >
        {t('access.permissions.removeAccessModal.text')} <br />
        &#39;{deleteAccess?.UserAccess.name}&#39;
      </ConfirmationModal>
      <MissingPermission />
      <Container fluid>
        {Object.keys(apps).map((appId) => (
          <UserAccess key={appId} userAccess={apps[appId]} onDeleteAccess={(a) => setDeleteAccess(a)} />
        ))}
      </Container>
    </Page>
  );
}

type IUserAccessProps = {
  userAccess: UserAccessResponse[];
  onDeleteAccess: (access: UserAccessResponseOrganization) => void;
};

function UserAccess({ userAccess, onDeleteAccess }: IUserAccessProps) {
  const { t } = useTranslation('pages');
  const header = userAccess[0];
  const name = header.applicationId ? header.applicationName : GlobalApplicationName;
  const url = header.applicationId ? header.applicationUrl : '';

  return (
    <Row className='app-group'>
      <Col>
        <Row className='header'>
          <div>
            <h4>{name}</h4>
            <span>{header.applicationDescription}</span>
          </div>
          {url && (
            <div
             className='app-link'
             onClick={() => window.open(url, '_blank')}
            >
              <IconAltArrowRight style={{ width: '14px' }} />
              <span>{t('access.permissions.openApplication')}</span>
            </div>
          )}
        </Row>

        {userAccess
          .sort(
            (ua1, ua2) =>
              (ua1.applicationName || '').localeCompare(ua2.applicationName || '') ||
              (ua1.name || '').localeCompare(ua2.name || '')
          )
          .map((access) => {
            let orgs =
              access.organizations?.map(
                (o) => ({ Organization: o, UserAccess: access } as UserAccessResponseOrganization)
              ) ?? [];
            if (orgs.length === 0) {
              orgs = [{ Organization: undefined, UserAccess: access }];
            }
            return orgs.map((o) => (
              <Row className='permission' key={o.Organization === undefined ? access.id : o.Organization?.organizationId}>
                <div>
                  <strong>
                    {access.name} {o.Organization ? `(${o.Organization.name})` : ''}
                  </strong>
                  {access.description && (
                    <p>
                      <small>{access.description}</small>
                    </p>
                  )}
                </div>
                <div className='duration'>
                  <strong>{t('access.permissions.durationLabel')}</strong>
                  {t('access.permissions.duration')}
                </div>
                <div
                  className='btn'
                  onClick={() => console.log('Renew access')}
                >
                  <IconCalendar style={{marginRight: 5}}/>
                  {t('access.permissions.renwewAccessRight')}
                </div>
                <div
                  className='btn'
                  onClick={() => onDeleteAccess(o)}
                >
                  <IconDelete style={{marginRight: 5}}/>
                  {t('access.permissions.removeAccessRight')}
                </div>
              </Row>
            ));
          })}
      </Col>
    </Row>
  );
}

function MissingPermission() {
  const { t } = useTranslation('pages');
  return (
    <>
      <Row>
        <Col lg='8'>
          <p className='missing-access'>{t('access.permissions.missingAccess')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg='8'>
          <div className='internal-link'>
            <IconAltArrowRight style={{ width: '14px' }} />
            <Link to={RoutePaths.accessRequest}>{t('access.permissions.createAccessRequest')}</Link>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Permissions;
